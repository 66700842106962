import * as React from 'react'
import { Wrapper } from '../layout'
import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import Head from '../components/Head'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { BarraNavegacion } from '../components/BarraNavegacion'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../styles/styleUtils'
import { PieDePagina } from '../components/PieDePagina'

interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`

const ImagenQuienesSomos = styled(Img)`
  width: 100%;
  object-fit: cover;
  margin-bottom: 0.4rem;
  break-inside: avoid;
`

const WrapperMultimedia = styled.div`
  display: block;
  column-count: 1;
  column-gap: 0.4rem;
  margin-bottom: 0.4rem;
  position: relative;
  overflow: hidden;
  @media (min-width: 1200px) {
    column-count: 4;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    column-count: 4;
  }
  @media (min-width: 700px) and (max-width: 899px) {
    column-count: 3;
  }
  @media (min-width: 400px) and (max-width: 699px) {
    column-count: 1;
  }
`

const TituloSeccion = styled.h3`
  position: absolute;
  bottom: 1.5rem;
  color: white;
  font-weight: 900;
  right: 1.5rem;
  left: 1.5rem;
  font-size: 1.3rem;
  text-align: right;
  text-shadow: 2px 2px grey;
  z-index: 2;
  ${media.md`
    font-size: 1rem;
  `}
  ${media.sm`
    font-size: 1rem;
    bottom: 1.5rem;
    right: 1.5rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  ${media.md`
    margin: 1rem;
  `}
`
const WrapperImageCabeceraDerecha = styled.div`
  position: relative;
  justify-content: center;

  width: 100%;
  overflow: hidden;
  text-align: center;
  ${media.md`
  `}
`

export default ({ data, location }: IndexPageProps) => {
  const ImageQueryMultimediaDeliCake = useStaticQuery(graphql`
    query ImageQueryMultimediaDeliCake {
      ChuletaDeTernera: file(
        relativePath: { eq: "imagenes/deli-cake/tarta-camiseta-osasuna.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      EsparragosRellenos: file(
        relativePath: { eq: "imagenes/deli-cake/tarta-camarero-borrasco.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CogoteDeMerluza: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-de-flores.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      MedallonesDeSolomillo: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-unicornio.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      RodaballoALaDonostiarra: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-super-mario.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ManitasDeCerdo: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-cumpleaños-elefante-y-leon.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ConfitDePato: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-balón-de-fútbol.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      RevueltoDeHongosConFoie: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-bob-esponja.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CrepesDeCuajada: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-personalizada-letra.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TorrijaDeBrioche: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-hakuna-matata.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TrufasConNata: file(
        relativePath: { eq: "imagenes/deli-cake/tarta-coronita.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CrepesConHelado: file(
        relativePath: {
          eq: "imagenes/deli-cake/tarta-luna-frostrie.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{data}</ThemeProvider>
        <BarraNavegacion />
        <WrapperBody>
          <Parrafo>
          ¿Una fecha señalada, un evento importante? No lo dudes y encarga tu tarta personalizada!!! Tartas Deli_cake. Ponte en contacto con nosotros y elige la temática, así como el sabor del bizcocho y el relleno, todo totalmente personalizado y a vuestro gusto. 
Visita nuestro Instagram <a target="_blank" href="https://www.instagram.com/deli_cake_15/?hl=es">@deli_cake_15</a>  para ver algunas de nuestras opciones y no perderte las novedades.
          </Parrafo>
          <Parrafo>
            Paty: <a href="tel:+34682652850">682652850</a>
          </Parrafo>
          <Parrafo>
          Andoni: <a href="tel:+34680349018">680349018</a>
          </Parrafo>
          <WrapperMultimedia>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Endivias con marisco'}
                fluid={
                  ImageQueryMultimediaDeliCake.ChuletaDeTernera.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'3 espárragos rellenos'}
                fluid={
                  ImageQueryMultimediaDeliCake.EsparragosRellenos.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Revuelto de hongos con Foie'}
                fluid={
                  ImageQueryMultimediaDeliCake.RevueltoDeHongosConFoie.childImageSharp
                    .fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Cogote de merluza al horno'}
                fluid={
                  ImageQueryMultimediaDeliCake.CogoteDeMerluza.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            {/* </WrapperMultimedia>
          <WrapperMultimedia> */}
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Rodaballo a la Donostiarra'}
                fluid={
                  ImageQueryMultimediaDeliCake.RodaballoALaDonostiarra.childImageSharp
                    .fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Medallones de solomillo con foie y salsa de Oporto'}
                fluid={
                  ImageQueryMultimediaDeliCake.MedallonesDeSolomillo.childImageSharp
                    .fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={
                  'Manitas de cerdo rellenas de foie y jamón ibérico con salsa de Pedro Ximénez'
                }
                fluid={
                  ImageQueryMultimediaDeliCake.ManitasDeCerdo.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Confit de pato con salsa agridulce'}
                fluid={ImageQueryMultimediaDeliCake.ConfitDePato.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Crepes rellenos de cuajada con salsa de naranja'}
                fluid={
                  ImageQueryMultimediaDeliCake.CrepesDeCuajada.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={
                  'Torrija de Brioche caramelizada sobre sopa de vainilla y helado de galleta María'
                }
                fluid={
                  ImageQueryMultimediaDeliCake.TorrijaDeBrioche.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Trufas con nata'}
                fluid={ImageQueryMultimediaDeliCake.CrepesConHelado.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <ImagenQuienesSomos
                alt={'Crepes con helado de café y chocolate caliente'}
                fluid={
                  ImageQueryMultimediaDeliCake.TrufasConNata.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
          </WrapperMultimedia>
        </WrapperBody>

        <PieDePagina />
      </Wrapper>
    </>
  )
}
